.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
}

.image {
  width: auto;
  height: 50%;
  background-color: transparent;
  margin: 5% 0;
  border: 5px dashed black;
}

@media only screen and (max-width: 600px) {
  .image {
    width: auto;
    height: 40%;
    margin: 5% 0;
  }
}

.name {
  background-color: red;
  color: white;
  padding: 10px;
  width: 80%;
  max-width: 500px;
  margin: 0;
}

.description {
  background-color: white;
  padding: 10px;
  width: 80%;
  max-width: 500px;
  margin: 0;
}
