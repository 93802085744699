.container {
  display: flex;
  justify-content: space-around;
  padding: 10px 0px;
  background-color: #fffc36;
  color: #002bfb;
}

.container a {
  text-decoration: none;
}

.active {
  color: red;
}
