.container {
  display: flex;
  background-color: black;
  width: 100%;
  color: #00f92f;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.8em;
  }
}

@media only screen and (max-width: 450px) {
  .title {
    font-size: 1.2em;
  }
}
