.hidden {
  display: none;
}

.wrapper {
  top: 0;
  left: 0;
  height: calc(100vh - 100px - 40px);
  width: 100vw;
}

.background {
  background-image: url("./../../assets/images/background-2.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}