.arrow {
  position: fixed;
  top: 50%;
  font-size: 30px;
  font-weight: bold;
  padding: 10px;
  background: white;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .arrow {
    font-size: 20px;
    top: 30%;
  }
}

.backward {
  left: 0;
}

.forward {
  right: 0;
}