.container {
  display: flex;
  position: relative;
  height: 100%;
  width: 400vw;
  overflow: hidden;
  left: 0;
  transition: left 2s;
}

.position--0 {
  left: 0;
}

.position--1 {
  left: -100vw;
}

.position--2 {
  left: -200vw;
}

.position--3 {
  left: -300vw;
}
