.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container h3 {
  background-color: white;
  border: 5px dashed black;
  padding: 5px;
}

.video {
  display: flex;
  justify-content: center;
  padding-bottom: 10vh;
}

.hidden {
  display: none;
}

.loading {
  display: flex;
  justify-content: center;
}

.loading p {
  color: red;
  font-size: 2em;
}
